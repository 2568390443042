import { Grid, LinearProgress } from '@clublabs/shared-component-library';
import { useNavigate } from 'react-router-dom';
import useProgressStore from '../../store/ProgressStore';
import CustomButton from './Button';
import './ProgressBar.css';

interface ProgressBarProps {
  to?: string;
  buttonDisabled?: boolean;
  fixed?: boolean;
  nextProgressValue?: number;
  displayBackButton: boolean;
  isInsideForm?: boolean;
  onClickContinue?: () => void;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  to,
  buttonDisabled,
  fixed,
  nextProgressValue,
  displayBackButton = false,
  isInsideForm = false,
  onClickContinue = () => {},
}: ProgressBarProps) => {
  const showProgress = useProgressStore((state) => state.showProgress);
  const progress = useProgressStore((state) => state.progress);
  const { setProgress } = useProgressStore();

  const navigate = useNavigate();

  const updateProgress = () => {
    onClickContinue();
    if (nextProgressValue) setProgress(nextProgressValue);
    if (to) navigate(to);
  };

  const goBackProgress = () => {
    navigate(-1);
  };

  return (
    <div
      style={{
        display: showProgress ? 'flex' : 'none',
        width: '100%',
        position: fixed ? 'fixed' : 'relative',
        bottom: 0,
      }}
      data-testid='progressBarContainer'
    >
      <Grid
        container
        columnGap={3}
        className={`progressBarContainer ${
          displayBackButton ? 'compactMobile' : ''
        }`}
      >
        {displayBackButton && (
          <Grid
            item
            order={{ desktop: 1, mobile: 1 }}
            data-testid='backButton'
            sx={{
              width: { mobile: '12%', desktop: '5rem' },
            }}
          >
            <CustomButton
              label='Back'
              onClickHandler={goBackProgress}
              variant='text'
            />
          </Grid>
        )}
        <Grid
          item
          order={{
            mobile: displayBackButton ? 2 : 1,
            desktop: displayBackButton ? 2 : 1,
          }}
          className={`progressBar ${
            displayBackButton ? 'largeDesktop smallMobile' : ''
          }`}
        >
          <LinearProgress
            data-testid='linearProgressBar'
            value={progress}
            variant='determinate'
            aria-label='progress'
            completeIndicator={true}
            aria-valuenow={progress}
            completeIndicatorProps={{
              sx: {
                '& svg': {
                  borderRadius: '50%',
                  display: 'block',
                  backgroundColor: (theme) => theme.palette.grey[200],
                },
              },
              completeIconProps: { sx: { display: 'none' } },
            }}
          />
        </Grid>
        <Grid
          item
          order={{
            desktop: displayBackButton ? 3 : 2,
            mobile: displayBackButton ? 3 : 2,
          }}
          data-testid='nextButton'
          sx={{
            width: { mobile: '10%', desktop: '5rem' },
          }}
        >
          <CustomButton
            label='Next'
            disabled={buttonDisabled}
            onClickHandler={updateProgress}
            type={isInsideForm ? 'submit' : 'button'}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ProgressBar;
