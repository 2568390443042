const cookieMonster = (cookieName: string) => {
  let tempCookie: string | undefined | null;
  const cookies = document.cookie.split(';');
  const cookieRegex = new RegExp(cookieName + '=', 'g');

  cookies.forEach((cookieVal) => {
    if (cookieVal.match(cookieRegex)) {
      tempCookie = decodeURI(cookieVal);
    }
  });

  if (
    tempCookie !== undefined &&
    tempCookie !== null &&
    tempCookie.indexOf('=') > 0
  ) {
    const match = tempCookie.match(new RegExp(`${cookieName}=(.+)$`));
    return match ? match[1] : null;
  }
  return null;
};

export default cookieMonster;
