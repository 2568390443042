export function getEmailOption(
  userEmail: string,
): 'Google' | 'Apple' | 'iCal' | 'Outlook.com' | 'Yahoo' {
  if (userEmail.includes('gmail')) return 'Google';
  if (userEmail.includes('outlook')) return 'Outlook.com';
  if (userEmail.includes('yahoo')) return 'Yahoo';
  if (userEmail.includes('icloud')) return 'Apple';

  return 'iCal';
}
