import ProfileImage from '@/components/shared/ProfileImage';
import { constants } from '@/constants';
import useLandingPageRedirection from '@/hooks/useLandingPageRedirection';
import { Page, TaggingService } from '@/lib/tagging';
import useAppointmentStore from '@/store/AppointmentStore';
import useSessionStore from '@/store/SessionStore';
import {
  AppHeader,
  Box,
  Button,
  ButtonGroup,
  Card,
  Icon,
  Link as LinkScl,
  Stack,
  Typography,
} from '@clublabs/shared-component-library';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { Link, useBlocker } from 'react-router-dom';

function AppointmentCancelled() {
  useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname,
  );
  const navigateTo = useLandingPageRedirection();
  const { confirmedAppointment, selectedDestination } = useAppointmentStore();

  const { sessionSource, sessionId, flowType } = useSessionStore();

  useEffect(() => {
    TaggingService.tag('view', sessionSource, {
      page: Page.cancelAppointment,
      sessionid: sessionId,
      flow: flowType,
      trv_end_location: selectedDestination!.name,
    });
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        backgroundColor: '#F7F8FA',
      }}
    >
      <AppHeader
        sx={{ position: 'absolute', top: 0 }}
        data-testid='appHeader'
      />
      <Box>
        <ProfileImage
          src={confirmedAppointment?.agentInfo?.image}
          alt='/travel/agent/scheduler/agentPlaceholder.png'
          size='responsive'
          testId='selectedAgentImage'
        />
      </Box>
      <Stack
        direction={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={0}
      >
        <Typography
          variant='h4'
          sx={{
            padding: 0,
            fontSize: { desktop: '30px', mobile: '24px' },
            marginTop: '10px',
          }}
          data-testid='agentMessage'
        >
          Your call is cancelled. We'll see you next time.
        </Typography>
      </Stack>
      <Stack
        direction={'column'}
        flexWrap={'wrap'}
        data-testid='callInfoCard'
        sx={{
          width: {
            desktop: '40%',
            tablet: '40%',
            mobile: '80%',
            large: '20%',
          },
          textAlign: 'left',
        }}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Card
          sx={{
            marginTop: '20px',
            marginBottom: '20px',
            padding: '1.5rem 1rem 1rem 1rem',
            display: 'flex',
            flexDirection: 'column',
            gap: {
              desktop: '20px',
              mobile: '10px',
            },
            '&.MuiPaper-generic': {
              width: 'auto',
            },
          }}
        >
          <Stack direction={'row'} alignItems={'center'} gap={'10px'}>
            <Icon
              iconName='calendar-circle-exclamation-duotone'
              color='error'
              sx={{ width: '35px', height: '35px' }}
            />
            <Typography
              sx={{
                fontWeight: '700',
                fontSize: { mobile: '16px', desktop: '24px' },
              }}
            >
              30 minute call with{' '}
              {confirmedAppointment?.agentInfo?.name?.split(' ')[0]}
            </Typography>
          </Stack>
          <Stack
            direction={'row'}
            alignItems={'baseline'}
            gap={'5px'}
            data-testid='dateAndTimeInfo'
          >
            <Typography
              variant='h6Medium'
              sx={{
                margin: 0,
                padding: 0,
                textAlign: 'left',
                fontSize: { mobile: '16px' },
                lineHeight: '20px',
              }}
            >
              When:{' '}
              <span style={{ fontWeight: 400 }}>
                {confirmedAppointment?.selectedDate
                  ? dayjs(confirmedAppointment?.selectedDate).format(
                      'dddd, MMMM D',
                    )
                  : dayjs(
                      new Date(confirmedAppointment?.selectedStartTime!),
                    ).format('dddd, MMMM D')}{' '}
                {confirmedAppointment?.selectedStartTime &&
                  confirmedAppointment?.selectedEndTime &&
                  `${dayjs(
                    new Date(confirmedAppointment?.selectedStartTime),
                  ).format(
                    'hh:mma',
                  )} - ${dayjs(new Date(confirmedAppointment?.selectedEndTime)).format('hh:mma')}`}
              </span>
            </Typography>
          </Stack>
          <Stack
            direction={'row'}
            alignItems={'baseline'}
            gap={'5px'}
            data-testid='destinationInfo'
          >
            <Typography
              variant='h6Medium'
              sx={{ margin: 0, padding: 0, fontSize: { mobile: '16px' } }}
            >
              Destination:
            </Typography>
            <Typography
              variant='b1Regular'
              sx={{ margin: 0, padding: 0, fontSize: { mobile: '16px' } }}
            >
              {selectedDestination?.name}
            </Typography>
          </Stack>
          <Stack
            direction={'row'}
            alignItems={'baseline'}
            gap={'5px'}
            data-testid='nextStepsMessage'
          >
            <Typography
              variant='h6Medium'
              sx={{
                margin: 0,
                padding: 0,
                fontSize: { mobile: '16px' },
                lineHeight: '20px',
              }}
            >
              Next Steps:{' '}
              <span style={{ fontWeight: 400 }}>
                You can always come back to schedule a new appointment.
              </span>
            </Typography>
          </Stack>
        </Card>
        <ButtonGroup
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px',
          }}
          data-testid='scheduleNewDateOrCloseButtonGroup'
        >
          <Link
            to={{ pathname: navigateTo }}
            reloadDocument
            style={{ width: '100%' }}
          >
            <Button label='Schedule new appointment' fullWidth />
          </Link>
          <LinkScl
            href={constants.TRAVEL_HOME_PAGE_URL}
            sx={{ width: '100%' }}
            data-testid='closePageButtonLink'
          >
            <Button label='Close page' fullWidth variant='outlined' />
          </LinkScl>
        </ButtonGroup>
      </Stack>
    </Box>
  );
}

export default AppointmentCancelled;
