import { apiWrapper } from '@/apiWrapper';
import { getEndpoints } from '@/endpoints';
import type { Confirmation } from '@/features/confirmation/utils/Confirmation';
import type { ModifyAppointmentRequestData } from '../utils/ModifyAppointmentRequestData';

export const patchCancel = async (
  cancelAppointmentData: ModifyAppointmentRequestData,
): Promise<Confirmation> => {
  const { appointmentId, ...requestBody } = cancelAppointmentData;
  const url = getEndpoints().modifyAppointment.replace(
    '{appointmentId}',
    appointmentId,
  );
  const config = { method: 'PATCH', data: requestBody };

  return await apiWrapper<Confirmation>(url, config);
};
