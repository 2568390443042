import { apiWrapper } from '@/apiWrapper';
import { getEndpoints } from '@/endpoints';
import { useQuery } from '@tanstack/react-query';

export const getAppointmentInformation = async (
  appointmentId: string,
): Promise<any> => {
  const url = getEndpoints().getAppointmentInformation.replace(
    '{appointmentId}',
    appointmentId,
  );
  const config = { method: 'GET' };

  return await apiWrapper<any>(url, config);
};

export const useAppointmentInfoQuery = (appointmentId: string) => {
  return useQuery({
    queryKey: ['appointmentInformation'],
    queryFn: () => getAppointmentInformation(appointmentId),
  });
};
