import { create } from 'zustand';

interface TSTParams {
  TSTTripURL: string;
}

interface TSTState {
  TSTParams: TSTParams;
  setTSTParams: (TSTParams: TSTParams) => void;
}

const useTSTStore = create<TSTState>((set) => ({
  TSTParams: {
    TSTTripURL: '',
  },
  setTSTParams: (TSTParams) => set({ TSTParams: TSTParams }),
}));

export default useTSTStore;
