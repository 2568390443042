import type { AgentInfo } from '@/features/appointments/utils/AgentInfo';
import type { ConfirmedAppointmentData } from '@/features/appointments/utils/ConfirmedAgentInfo';
import type { ContactInfo } from '@/features/appointments/utils/ContactInfo';
import type { Destination } from '@/features/destinations/utils/Destination';
import { create } from 'zustand';

interface AppointmentState {
  selectedDestination: Destination;
  selectedDate: Date | undefined;
  selectedStartTime?: string;
  selectedEndTime?: string;
  selectedAgent: Partial<AgentInfo>;
  contactInfo: ContactInfo;
  customerId: string;
  accountId: string;
  appointmentId?: string;
  confirmationId?: string;
  confirmedAppointment?: ConfirmedAppointmentData;
  setSelectedDestination: (destination: Destination) => void;
  setSelectedDate: (date: Date | undefined) => void;
  setSelectedStartTime: (time: string) => void;
  setSelectedEndTime: (time: string) => void;
  setSelectedAgent: (agent: Partial<AgentInfo>) => void;
  setContactInfo: (contactInfo: ContactInfo) => void;
  setCustomerId: (id: string) => void;
  setAccountId: (id: string) => void;
  setAppointmentId: (id: string) => void;
  setConfirmationId: (id: string) => void;
  setConfirmedAppointment: (appointment: ConfirmedAppointmentData) => void;
}

const useAppointmentStore = create<AppointmentState>((set) => ({
  selectedDestination: { workTypeId: '', name: '' },
  selectedDate: undefined,
  selectedStartTime: '',
  selectedEndTime: '',
  contactInfo: {
    expectedTravelTime: '0-3 Months',
  },
  customerId: '',
  confirmedAppointment: undefined,
  accountId: '',
  selectedAgent: {},
  setSelectedDestination: (destination) => {
    set({ selectedDestination: destination });
  },
  setSelectedDate: (date) => set({ selectedDate: date }),
  setSelectedStartTime: (time) => set({ selectedStartTime: time }),
  setSelectedEndTime: (time) => set({ selectedEndTime: time }),
  setSelectedAgent: (agent) => set({ selectedAgent: agent }),
  setContactInfo: (contactInfo) => set({ contactInfo: contactInfo }),
  setCustomerId: (id) => set({ customerId: id }),
  setAccountId: (id) => set({ accountId: id }),
  setAppointmentId: (id) => set({ appointmentId: id }),
  setConfirmationId: (id) => set({ confirmationId: id }),
  setConfirmedAppointment: (appointment) =>
    set({ confirmedAppointment: appointment }),
}));

export default useAppointmentStore;
