interface Endpoints {
  destinations: string;
  timeSlots: string;
  customerAccount: string;
  createAppointment: string;
  modifyAppointment: string;
  getAppointmentInformation: string;
  createEmailLead: string;
}

export const endpointPaths: Endpoints = {
  destinations: '/travel-scheduler/scheduler/v1/destinations',
  timeSlots: '/travel-scheduler/scheduler/v1/agents-and-timeslots/{workTypeId}',
  customerAccount: '/travel-scheduler/scheduler/v1/customer-account',
  modifyAppointment:
    '/travel-scheduler/scheduler/v1/modify-appointment/{appointmentId}',
  createAppointment: '/travel-scheduler/scheduler/v1/appointment',
  getAppointmentInformation:
    '/travel-scheduler/scheduler/v1/appointment/{appointmentId}',
  createEmailLead: '/travel-scheduler/scheduler/v1/process-email-inquiry',
};

export enum Environments {
  test = 'mockapi',
  development = 'mockapi',
  dev = 'dev',
  qa = 'qa',
  uat = 'uat',
  prod = 'prod',
}

export const prodEnvironments: Array<Environments> = [Environments.prod];

export const getApiDomain = (env: Environments): string => {
  switch (env) {
    case Environments.development:
      return 'mockapi';
    case Environments.prod:
      return 'common.api.ace.aaa.com';
    default:
      return `common.api.${env}.ace.aaa.com`;
  }
};

export const isEnvironmentProd = (hostname: string): boolean => {
  return prodEnvironments.includes(getEnvironmentFromHost(hostname));
};

export const getEnvironmentFromHost = (hostname: string): Environments => {
  if (
    hostname.search('localhost') > -1 &&
    (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test')
  ) {
    return Environments.development;
  }

  if (hostname.search('localhost') > -1) {
    return Environments.dev;
  }

  if (hostname.startsWith('appdev1') || hostname.startsWith('appdev2')) {
    return Environments.dev;
  }

  if (hostname.startsWith('appqa1') || hostname.startsWith('appqa2')) {
    return Environments.qa;
  }

  if (hostname.startsWith('appuat1') || hostname.startsWith('appuat2')) {
    return Environments.uat;
  }

  if (hostname.startsWith('app.ace')) {
    return Environments.prod;
  }

  return Environments.uat;
};

const generateEndpoints = (hostname: string): Endpoints => {
  const env = getEnvironmentFromHost(hostname);
  const protocol: string = ['development', 'test'].includes(
    process.env.NODE_ENV || 'development',
  )
    ? 'http'
    : 'https';
  const _endpoints: Endpoints = {
    destinations: `${protocol}://${getApiDomain(env)}${
      endpointPaths.destinations
    }`,
    timeSlots: `${protocol}://${getApiDomain(env)}${endpointPaths.timeSlots}`,
    customerAccount: `${protocol}://${getApiDomain(env)}${
      endpointPaths.customerAccount
    }`,
    createAppointment: `${protocol}://${getApiDomain(env)}${
      endpointPaths.createAppointment
    }`,
    modifyAppointment: `${protocol}://${getApiDomain(env)}${
      endpointPaths.modifyAppointment
    }`,
    getAppointmentInformation: `${protocol}://${getApiDomain(env)}${
      endpointPaths.getAppointmentInformation
    }`,
    createEmailLead: `${protocol}://${getApiDomain(env)}${
      endpointPaths.createEmailLead
    }`,
  };
  return _endpoints;
};

export const getEndpoints = () => generateEndpoints(window.location.hostname);
