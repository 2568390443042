import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import { ErrorBoundary, type FallbackProps } from 'react-error-boundary';
import { RouterProvider } from 'react-router-dom';

import ErrorPage from '@/components/shared/ErrorPage';
import { queryClient } from '@/lib/queryClient';
import { initLogCannon } from '@/providers/log-cannon';
import { router } from '@/routes';
import {
  CircularProgress,
  ThemeProvider,
} from '@clublabs/shared-component-library';

export const ErrorFallback = ({ resetErrorBoundary }: FallbackProps) => {
  return <ErrorPage onClickTryAgain={resetErrorBoundary} />;
};

export const AppProvider = () => {
  initLogCannon();
  return (
    <ThemeProvider>
      <React.Suspense
        fallback={
          <div className='flex items-center justify-center w-screen h-screen'>
            <CircularProgress />
          </div>
        }
      >
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <QueryClientProvider client={queryClient}>
            {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools />}
            <RouterProvider router={router} />
          </QueryClientProvider>
        </ErrorBoundary>
      </React.Suspense>
    </ThemeProvider>
  );
};
