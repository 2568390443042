import { getEnvironmentFromHost } from '@/endpoints';
import useSessionStore from '@/store/SessionStore';
import { ulid } from 'ulid';

export function createAxiosConfig() {
  const xRequestId = ulid();

  const config = {
    headers: {
      'ace-data-region': getEnvironmentFromHost(window.location.hostname),
      'x-request-id': xRequestId,
      'x-session-id': useSessionStore.getState().sessionId,
    },
  };
  return config;
}
