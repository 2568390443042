import { apiWrapper } from '@/apiWrapper';
import { getEndpoints } from '@/endpoints';
import type { ProcessEmailInquiryData } from '@/features/confirmation/utils/ProcessEmailInquiryData';
import type { ProcessEmailInquiryResponse } from '../utils/ProcessEmailInquiryResponse';

export const postProcessEmailInquiry = async (
  inputData: ProcessEmailInquiryData,
): Promise<ProcessEmailInquiryResponse> => {
  const url = getEndpoints().createEmailLead;
  const config = { method: 'POST', data: inputData };

  return await apiWrapper<ProcessEmailInquiryResponse>(url, config);
};
