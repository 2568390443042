import { apiWrapper } from '@/apiWrapper';
import { getEndpoints } from '@/endpoints';
import type { Confirmation } from '@/features/confirmation/utils/Confirmation';
import type { CreateAppointmentData } from '@/features/confirmation/utils/CreateAppointmentData';

export const postConfirmation = async (
  inputData: CreateAppointmentData,
): Promise<Confirmation> => {
  const url = getEndpoints().createAppointment;
  const config = { method: 'POST', data: inputData };

  return await apiWrapper<Confirmation>(url, config);
};
