export const constants = {
  SERVICE_DESK_NUMBER: '+1(800) 453-9604',
  TRAVEL_HOME_PAGE_URL: 'https://www.ace.aaa.com/travel',
  TRAVEL_TOURBOOK_GUIDES_URL:
    'https://www.ace.aaa.com/travel/aaa-maps-and-tourbook-guides.html',
  DRIVING_DIRECTIONS_URL: 'https://triptik.aaa.com/home/',
  PASSPORT_PHOTOS_URL:
    'https://www.ace.aaa.com/travel/information/passport-information-and-services.html',
  DRIVING_PERMITS_URL:
    'https://www.ace.aaa.com/travel/information/international-driving-permit.html',
  FOREIGN_CURRENCY_URL: 'https://www.ace.aaa.com/financial/vacation-money.html',
  TRAVEL_INSURANCE_URL: 'https://www.ace.aaa.com/travel/travel-insurance.html',
  TRAVEL_ARTICLES_URL: 'https://www.ace.aaa.com/aaa-insider/travel.html',
  AAA_BRANCH_URL: 'https://www.aaa.com/office/',
  CONTACT_URL: 'https://www.ace.aaa.com/information/contact-us.html',
  IMAGE_BASE_URL: '/travel/agent/scheduler/destinationImages',
  APP_BASE_URL: '/travel/agent/scheduler',
  EMAIL_FLOW_BASE_URL: '/travel/agent/scheduler/?contactType=email',
  TANSTACK_ABORTED_ERROR: 'Request aborted',
  DEFAULT_WHEN_DO_YOU_PLAN_TO_TRAVEL: '0-3 Months',
};
