import { blastDataToLogCannon } from '@/utils/blastDataToLogCannon';
import { createAxiosConfig } from '@/utils/createAxiosConfig';
import axios, {
  type AxiosRequestConfig,
  type AxiosResponse,
  type AxiosError,
} from 'axios';

export const apiWrapper = async <T>(
  url: string,
  config?: AxiosRequestConfig,
): Promise<T> => {
  const axiosConfig = { ...createAxiosConfig(), ...config };
  const startTime = Date.now();

  try {
    const response: AxiosResponse<T> = await axios(url, axiosConfig);
    const endTime = Date.now();

    // Log successful request
    blastDataToLogCannon({
      ...axiosConfig.headers,
      url,
      method: axiosConfig.method,
      status: response.status,
      responseTime: endTime - startTime,
    });

    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError;
    const endTime = Date.now();

    // Log failed request
    blastDataToLogCannon({
      ...axiosConfig.headers,
      response: axiosError.response ? axiosError.response.data : '',
      url,
      method: axiosConfig.method,
      status: axiosError.response
        ? axiosError.response.status
        : 'Network Error',
      responseTime: endTime - startTime,
      errorMessage: axiosError.message,
    });

    throw error;
  }
};
