import { Region } from '@/features/destinations/utils/Region';

export const activitiesByRegionMapper: { [key in Region]: string[] } = {
  [Region.Africa]: [
    'Across the continent, Africa offers a wide variety of vacation experiences by land or waterway, including to Kenya, Tanzania, South Africa, and Egypt',
    "Embark on a safari adventure to witness Africa's diverse wildlife in their natural habitats",
    'Immerse yourself in the rich heritage of African music, dance, and art through interactive experiences',
    'Cruise down the Nile and discover ancient civilizations and historical sites like the Pyramids of Giza and Great Sphinx',
    'Learn the art of Moroccan cuisine using authentic techniques and spices in an interactive cooking class',
  ],
  [Region.Alaska]: [
    'Whether cruising the waters or exploring on land, the majestic scenery of Alaska will surround you',
    'Cruise past breathtaking glaciers and witness stunning ice formations and marine wildlife',
    "Explore Denali National Park, home to North America's tallest peak, and enjoy hiking and viewing of Alaska’s Big 5: bears, moose, caribou, Dall sheep, and wolves",
    "Take a scenic train ride through Alaska's rugged landscapes",
    'Stop at picturesque ports and explore coastal communities',
    'Immerse yourself in the natural wonders of the Aurora Borealis with a Northern Lights tour during the winter months',
  ],
  [Region.EasternEurope]: [
    'Eastern Europe features historic cities known for their stunning architecture, charming old towns, and rich cultural heritage',
    'Take a river cruise along the Danube or Rhine Rivers, passing through picturesque countryside, charming villages, and historic cities.',
    'Experience the rich musical traditions of Central and Eastern Europe through concerts, operas, and folk festivals',
    'Sample delicious cuisine from across the region, including hearty stews, savory pastries, and traditional desserts',
  ],
  [Region.AustraliaAndNewZealand]: [
    'Explore the Great Barrier Reef through snorkeling or diving',
    'Visit iconic landmarks like the Sydney Opera House and Sydney Harbour Bridge',
    'Discover the stunning landscapes of the Outback, including Uluru (Ayers Rock)',
    'Relax on the pristine beaches of the Gold Coast or Bondi Beach',
    'Explore the ancient rainforests of the Daintree in Queensland',
    'Discover the breathtaking landscapes of Fiordland National Park, including Milford Sound',
    'Experience adrenaline-pumping activities in Queenstown, such as bungee jumping or jet boating',
  ],
  [Region.Caribbean]: [
    'Whether at a luxury resort, all-inclusive resort, or ocean cruise, immerse yourself in the laid-back island lifestyle in the Caribbean',
    'Relax on pristine white-sand beaches and swim in crystal-clear turquoise waters',
    'Snorkel or scuba dive among vibrant coral reefs and diverse marine life',
    'Explore lush rainforests and hike to stunning waterfalls',
    'Experience the vibrant culture of the Caribbean through music, dance, and local cuisine',
  ],
  [Region.CentralAndSouthAmerica]: [
    'Central America offers a diverse array of vacation options, from beaches to jungles',
    'Relax on beautiful beaches along the Caribbean and Pacific coasts, perfect for swimming, surfing, and sunbathing',
    'Go snorkeling or scuba diving in the world-renowned coral reefs of Belize or Honduras',
    'Enjoy thrilling outdoor adventures such as zip-lining, white-water rafting, and canopy tours in Costa Rica',
    'Visit UNESCO World Heritage Sites such as the Panama Canal or the Darien National Park in Panama',
    'Explore the Amazon Rainforest, one of the most biodiverse regions on Earth, and discover unique wildlife, indigenous cultures, and stunning natural landscapes',
    'Visit iconic landmarks such as Machu Picchu in Peru, Christ the Redeemer in Brazil, and the Galapagos Islands in Ecuador',
    'Experience the vibrant culture and nightlife of cities like Buenos Aires, Rio de Janeiro, and Bogota, with their museums, theaters, and lively street markets',
    'Take a land or cruise journey of exploration to the diverse regions of South America and Antarctica',
  ],
  [Region.CentralWesternEurope]: [
    'Central Europe features historic cities known for their stunning architecture, charming old towns, and rich cultural heritage',
    'Take a river cruise along the Danube or Rhine Rivers, passing through picturesque countryside, charming villages, and historic cities',
  ],
  [Region.Hawaii]: [
    'From relaxing on world-renowned beaches and rejuvenating at luxurious spa retreats to enjoying the outdoor activities like hiking, golf, surfing, or snorkeling, a Hawaiian vacation has it all',
    'Explore breathtaking natural wonders such as the Na Pali Coast, Haleakalā National Park, and Waimea Canyon',
    'Embark on a scenic drive along the Hana Highway, passing through lush rainforests, waterfalls, and dramatic coastal scenery',
    "Take a helicopter tour to see Hawaii's stunning landscapes from above, including active volcanoes and cascading waterfalls",
    "Experience traditional Hawaiian culture through hula performances, luau feasts, and visits to the Polynesian Cultural Center, Pu'uhonua o Hōnaunau National Historical Park, and Kualoa Ranch",
  ],
  [Region.ScandinaviaAndIceland]: [
    'Journey to experience the natural beauty of Northern Europe and Scandinavia’s fjords and mountains on a scenic train ride or cruise',
    'Take in the magic of the Northern Lights in destinations like Norway and Sweden, including stays in glass-top igloos',
    'Explore the charming medieval streets and historic landmarks of cities like Stockholm, Copenhagen, and Tallinn',
    'Go hiking, skiing, or dog sledding in the stunning landscapes of Lapland, Finland',
    'Explore the vibrant arts and culture scene of cities like Helsinki, Oslo, and Reykjavik, with their museums, galleries, and music festivals',
    'Sample delicious Nordic cuisine, including fresh seafood, hearty stews, and iconic dishes like Swedish meatballs and Danish pastries',
    'Experience the otherworldly landscapes of Iceland, with its geysers, waterfalls, natural hot springs, glaciers, and volcanic terrain',
  ],
  [Region.SouthernEuropeAndMediterranean]: [
    'Whether you cruise or travel by land, a Mediterranean vacation will include the picturesque coastline, charming coastal towns, and delicious cuisine, including fresh seafood, olives, olive oil, and locally produced wine',
    'Relax on stunning beaches with crystal-clear waters and golden sands or rejuvenate at luxurious spa retreats along the coast',
    'Explore historic cities and ancient ruins, in Rome, Italy; Athens, Greece; and Ephesus, Turkey',
    'Experience vibrant nightlife in cities like Barcelona and Ibiza, Spain and Mykonos, Greece',
  ],
  [Region.Asia]: [
    'Throughout the continent, Asia offers a full array of vacation experiences by land or water',
    'Explore the ancient temples of Angkor Wat in Cambodia, a UNESCO World Heritage Site',
    'Discover the bustling streets, vibrant markets, and delicious street food of cities like Bangkok, Hanoi, and Tokyo',
    'Relax on the pristine beaches of Bali, Phuket, or the Maldives, and enjoy activities such as snorkeling, diving, and surfing',
    'Experience the rich cultural heritage of Japan through traditional tea ceremonies, sumo wrestling matches, and visits to historic castles and shrines',
    "Marvel at the iconic skyline of Hong Kong and explore the city's vibrant neighborhoods, shopping districts, and dining scene",
    "Cruise along the picturesque rivers and waterways of Vietnam's Mekong Delta, and visit floating markets, traditional villages, and ancient pagodas",
  ],
  [Region.Mexico]: [
    "Whether at a luxury resort, all-inclusive resort, or ocean cruise, immerse yourself in the laid-back island lifestyle of Mexico's coastal towns ",
    'Relax on stunning beaches along the Pacific and Caribbean coasts, including Cancun, Riviera Maya, Tulum, Cabo San Lucas, and Puerto Vallarta',
    'Explore ancient Mayan and Aztec ruins, such as Chichen Itza, Teotihuacan, and Palenque',
    'Experience the thrills at a variety of waterparks, adventure parks, and ziplining attractions',
    'Discover vibrant cities known for their historic architecture, cultural attractions, and lively street markets',
    'Indulge in delicious Mexican cuisine, including tacos, tamales, mole, and fresh seafood dishes',
  ],
  [Region.TahitiAndSouthPacific]: [
    'Surround yourself in luxury with resorts, overwater bungalows, and locations that will have you immersed in the serene beauty of the South Pacific',
    'Relax on pristine beaches with turquoise waters and white sands in destinations such as Fiji, Bora Bora, and Tahiti',
    'Visit historic sites and cultural landmarks, including ancient Polynesian temples, traditional villages, and colonial-era buildings',
    'Go snorkeling or scuba diving to discover colorful coral gardens, tropical fish, and other marine creatures',
    'Sample delicious cuisine featuring fresh seafood, tropical fruits, and traditional dishes influenced by Polynesian, Melanesian, and Micronesian cultures',
  ],
  [Region.UKAndIreland]: [
    'Journey overseas for a vacation rich in history, culture, cuisine, castles',
    'Explore historic landmarks and iconic attractions such as Big Ben, the Tower of London, Buckingham Palace, Windsor Castle, Edinburgh Castle, and Blarney Castle',
    'Discover the breathtaking landscapes of the Scottish Highlands, the Lake District, and Ireland’s Cliffs of Moher',
    'Visit picturesque villages and charming towns, such as Bath, Stratford-upon-Avon, and Galway',
    'Go hiking, horseback riding, or cycling in the beautiful countryside of England, Ireland, and Scotland',
  ],
  [Region.USAAndCanada]: [
    'A U.S. vacation can be as diverse as our population. Stay close to home and soak in our national treasures',
    'Discover stunning national parks such as Yellowstone, Yosemite, and the Great Smoky Mountains',
    'Visit vibrant cities like New York City, Los Angeles, Chicago, and Las Vegas, known for their cultural attractions, dining scenes, and nightlife',
    'Experience the magic of a family theme park vacation, filled with thrilling attractions, entertainment and beloved characters',
    'Cruise along America’s waterways, passing through scenic landscapes, charming towns, and historic landmarks',
    'Enjoy outdoor activities such as skiing, snowboarding, and snowshoeing in winter, and hiking, kayaking, and biking in summer, in destinations across the country',
    'Experience the vibrant arts and music scenes of cities like New Orleans, Nashville, and Austin, with live performances, festivals, and cultural events throughout the year',
    'Canada offers vacation experiences for all seasons for unique and unforgettable experiences by land, ocean, and train',
    'Explore the natural beauty of Banff National Park in the Canadian Rockies, with opportunities for hiking, wildlife spotting, and stunning mountain vistas',
    'Experience the vibrant culture and diverse cuisine of cities like Toronto, Vancouver, and Montreal, known for their bustling neighborhoods, world-class restaurants, and lively arts scenes',
    'Discover the historic charm of Quebec City, with its cobblestone streets, centuries-old architecture, and iconic landmarks such as the Château Frontenac',
    "Visit the picturesque coastal towns of Nova Scotia, including Peggy's Cove and Lunenburg, known for their colorful houses, lighthouses, and maritime heritage",
  ],
  [Region.Other]: [
    'The world awaits, with destinations and experiences to fit all travel styles',
  ],
};
