import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ErrorPage from '@/components/shared/ErrorPage';
import LoadingContainer from '@/components/shared/LoadingContainer';
import { constants } from '@/constants';
import { useAppointmentInfoQuery } from '@/features/loadAppointment/api/getAppointmentInformation';
import useAppointmentStore from '@/store/AppointmentStore';
import useSessionStore from '@/store/SessionStore';
import { ulid } from 'ulid';

function LoadAppointment() {
  const [searchParams, _] = useSearchParams();
  const navigate = useNavigate();
  const {
    setSelectedDestination,
    setSelectedAgent,
    setAppointmentId,
    setContactInfo,
    setSelectedStartTime,
    setSelectedEndTime,
    setCustomerId,
    setConfirmationId,
    setConfirmedAppointment,
  } = useAppointmentStore();

  const { setSessionSource, setSessionId, sessionId } = useSessionStore();

  const [isDoneFetching, setIsDoneFetching] = useState(false);

  const { data, isError, refetch } = useAppointmentInfoQuery(
    searchParams.get('appointmentId') ?? '',
  );

  useEffect(() => {
    if (data) {
      setIsDoneFetching(true);
      if (data.status === 'Canceled') {
        return;
      }
      setConfirmedAppointment({
        agentInfo: {
          agentRecordId: data.agentUserId,
          resourceId: data.appointmentServiceResourceId,
          serviceTerritoryId: data.serviceTerritoryId,
          name: data.name,
          image: data.image,
        },
        selectedDate: new Date(data.schedStartTime),
        selectedStartTime: data.schedStartTime,
        selectedEndTime: data.schedEndTime,
      });
      setSelectedDestination({
        name: data.destination,
        workTypeId: data.workTypeId,
      });
      setSelectedAgent({
        agentRecordId: data.agentUserId,
        resourceId: data.appointmentServiceResourceId,
        serviceTerritoryId: data.serviceTerritoryId,
        name: data.name,
        image: data.image,
      });
      setAppointmentId(data.appointmentId);
      setContactInfo({ email: data.email });
      setSelectedStartTime(data.schedStartTime);
      setSelectedEndTime(data.schedEndTime);
      setConfirmationId(data.appointmentId);
      setCustomerId(data.customerId);
      setTimeout(() => {
        setSessionSource('email');
        if (!sessionId) {
          const newSessionId = ulid();
          setSessionId(newSessionId);
        }
        navigate('/confirmation?source=email');
      }, 250);
    }
  }, [data]);

  if (isError) {
    return <ErrorPage onClickTryAgain={refetch} />;
  }

  if (data?.status === 'Canceled') {
    return (
      <ErrorPage
        onClickTryAgain={() => {
          window.location.replace(`${constants.APP_BASE_URL}`);
        }}
        message={`It seems you've already canceled this appointment.`}
        imageUrl='/travel/agent/scheduler/canceledAppointmentImage.png'
        buttonLabel='Start over'
      />
    );
  }

  return (
    <LoadingContainer
      message={`Hang tight while we're validating your appointment…`}
      radiosInfo={[
        { radioMessage: 'Retrieving your time slot.', isChecked: true },
        {
          radioMessage: 'Retrieving agent information.',
          isChecked: isDoneFetching,
        },
      ]}
    />
  );
}
export default LoadAppointment;
