import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

async function enableMocking() {
  if (
    import.meta.env.VITE_ENVIRONMENT !== '' ||
    process.env.NODE_ENV !== 'development'
  ) {
    return;
  }

  try {
    const { worker } = await import('../mocks/browser');
    // `worker.start()` returns a Promise that resolves
    // once the Service Worker is up and ready to intercept requests.
    return worker.start({
      serviceWorker: {
        url: '/travel/agent/scheduler/mockServiceWorker.js',
      },
    });
  } catch (error) {
    console.error('Failed to load worker:', error);
  }

  return;
}

enableMocking()
  .then(() => {
    // biome-ignore lint/style/noNonNullAssertion: It's fine for this one
    ReactDOM.createRoot(document.getElementById('root')!).render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
    );
  })
  .catch((error) => console.error('Failed to enable mocking:', error));
