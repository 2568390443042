import {
  Box,
  Divider,
  Icon,
  Stack,
  Typography,
} from '@clublabs/shared-component-library';

import { constants } from '@/constants';
import type { AgentInfo } from '@/features/appointments/utils/AgentInfo';
import AgentInfoModal from './AgentInfoModal';

interface PickDifferentAgentModalBodyProps {
  agentsInfo: AgentInfo[];
  selectDifferentAgentHandler: (agentId: string) => void;
}

function PickDifferentAgentModalBody({
  selectDifferentAgentHandler,
  agentsInfo,
}: PickDifferentAgentModalBodyProps) {
  if (!agentsInfo || agentsInfo.length === 0) {
    return (
      <Stack
        direction={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        data-testid={'unavailableAgentContainer'}
        gap={1}
        sx={{
          padding: '0 20px 20px 20px ',
          height: '400px',
          backgroundColor: '#FCFBF7',
        }}
      >
        <Icon
          iconName={'location-pin-slash-regular'}
          color={'primary'}
          sx={{ width: '50px', height: '40px' }}
        />
        <Typography align={'center'} variant={'h5Medium'}>
          Other agents are currently unavailable.{' '}
        </Typography>
        <Typography
          align={'center'}
          variant={'b1Regular'}
          sx={{ color: (themes) => themes.palette.grey[500] }}
        >
          Please try again later or call{' '}
          <a
            style={{ color: '#707070' }}
            href={`tel:${constants.SERVICE_DESK_NUMBER}`}
          >
            {constants.SERVICE_DESK_NUMBER}
          </a>
        </Typography>
      </Stack>
    );
  }
  return (
    <Box data-testid='agentsContainer'>
      {agentsInfo?.map((agent) => (
        <Box key={agent.resourceId}>
          <AgentInfoModal
            id={agent.resourceId}
            image={agent.image}
            name={agent.name}
            location={agent.location}
            nearestTimeSlot={agent.timeSlots[0].startTime}
            selectAgentHandler={selectDifferentAgentHandler}
          />
          <Divider />
        </Box>
      ))}
    </Box>
  );
}

export default PickDifferentAgentModalBody;
