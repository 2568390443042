import { Paper, Stack, Typography } from '@clublabs/shared-component-library';
import './DestinationCard.css';

interface DestinationCardProps {
  label: string;
  imageUrl: string;
  destinationWorkTypeId: string;
  destinationName: string;
  isSelected: boolean;
  onSelect: (destinationWorkTypeId: string, destinationName: string) => void;
}

const DestinationCard = ({
  label,
  destinationWorkTypeId,
  destinationName,
  imageUrl,
  isSelected,
  onSelect,
}: DestinationCardProps) => {
  const onClickHandler = () => {
    onSelect(destinationWorkTypeId, destinationName);
  };

  return (
    // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
    <div
      className={`DestinationCard ${isSelected ? 'selected' : ''}`}
      onClick={onClickHandler}
      data-testid={`destinationCard-${destinationWorkTypeId}`}
      tabIndex={0}
      onKeyDown={(event) => {
        if (!isSelected) event.stopPropagation();
        if (event.key === 'Enter') {
          onClickHandler();
        }
      }}
    >
      <Paper elevation={2} sx={{ height: 149, width: 164, overflow: 'hidden' }}>
        <Stack direction='column' justifyContent='center' alignItems='center'>
          <img
            data-testid='destinationImages'
            src={imageUrl}
            width={'100%'}
            height={100}
            alt={label}
          />
          <div className='destinationCardFooter' data-testid='destinationTitle'>
            <Typography
              className='destinationCardTitle'
              variant='b1Medium'
              align='center'
            >
              {label}
            </Typography>
          </div>
        </Stack>
      </Paper>
    </div>
  );
};

export default DestinationCard;
