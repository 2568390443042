import type { FlowType, SessionSource } from '@/store/SessionStore';
import cookieManager from './cookieHelper';

interface UTag {
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  link: (linkParams: Record<string, any>) => void;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  view: (viewParams: Record<string, any>) => void;
}

interface Flows {
  new: string;
  modify: string;
  cancel: string;
  email: string;
  emailModify: string;
  emailCancel: string;
}

interface Pages {
  destination: string;
  selectAppointment: string;
  selectAgent: string;
  anotherAgent: string;
  contactInfo: string;
  confirmation: string;
  cancelAppointment: string;
  errorPage: string;
}

interface CTAS {
  pickAnotherAgent: string;
}

// have to be all lowercase
interface TagParams {
  sessionid: string;
  page: string;
  flow: FlowType;
  trv_end_location?: string;
  cta?: string;
  confirmation_id?: string;
  category?: Array<string>;
  comm_pref?: string;
  custID?: string;
  quantity?: Array<string>;
  product?: Array<string>;
  error?: string;
}

export const Page: Pages = {
  destination: 'travel:scheduler:destination',
  selectAppointment: 'travel:scheduler:select appointment',
  selectAgent: 'travel:scheduler:select an agent',
  anotherAgent: 'travel:scheduler:select appointment-pick another agent',
  contactInfo: 'travel:scheduler:contact information',
  confirmation: 'travel:scheduler:confirmation',
  cancelAppointment: 'travel:scheduler:cancel appointment',
  errorPage: 'travel:scheduler:error',
};

export const CTA: CTAS = {
  pickAnotherAgent: 'travel:scheduler:select appointment-pick another agent',
};

export const Flow: Flows = {
  new: 'new',
  modify: 'new:modify',
  cancel: 'new:cancel',
  email: 'email',
  emailModify: 'email:modify',
  emailCancel: 'email:cancel',
};

export const getClubCode = () => {
  const zipCookie = cookieManager('zipcode');
  if (zipCookie !== null) {
    const clubcode = zipCookie.split('|')[2];
    return clubcode ? clubcode.padStart(3, '0') : '';
  }
  return '';
};

export const deriveFlowTag = (
  sessionSource: SessionSource,
  flowType: FlowType,
): string => {
  if (sessionSource === 'new' && flowType === '') return 'new';

  if (sessionSource === 'new' && flowType === 'modify') return 'new:modify';

  if (sessionSource === 'new' && flowType === 'cancel') return 'new:cancel';

  if (sessionSource === 'email' && flowType === '') return 'email';

  if (sessionSource === 'new' && flowType === 'emailInquiry')
    return 'advisor inquiry';

  if (sessionSource === 'email' && flowType === 'modify') return 'email:modify';

  if (sessionSource === 'email' && flowType === 'cancel') return 'email:cancel';

  return 'new';
};

declare const utag: UTag;

type ActivityType = 'view' | 'link';

const commonTags = {
  channel: 'travel', // content category
  clubcode: getClubCode(), // club code
  subchannel1: 'scheduler', // content sub channel
};

async function tag(
  activityType: ActivityType,
  sessionSource: SessionSource,
  tags: TagParams,
  retryCount = 0,
) {
  const maxRetryCount = 5;
  if (retryCount > maxRetryCount) {
    return;
  }
  const lowercaseDestination = (tags.trv_end_location || '').toLowerCase();
  const lowercaseCommPref = tags.comm_pref?.toLowerCase();
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  (window as any).aceMediaTagValues = {
    ...commonTags,
    ...tags,
    ...(lowercaseCommPref ? { comm_pref: lowercaseCommPref } : {}),
    trv_end_location: lowercaseDestination,
    flow: deriveFlowTag(sessionSource, tags.flow),
  };

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  if (typeof (window as any).utag === 'undefined') {
    loadUtagJs();
  } else {
    if (activityType === 'view') {
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      (window as any).utag.view((window as any).aceMediaTagValues);
    } else if (activityType === 'link') {
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      (window as any).utag.link((window as any).aceMediaTagValues);
    }
  }
}

function loadUtagJs() {
  // biome-ignore lint/suspicious/noExplicitAny: <expQlanation>
  if ((window as any).utagLoadAttempt) return;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  (window as any).utagLoadAttempt = true;
  const utagJsScript = document.createElement('script');
  utagJsScript.src = '//tms.ace.aaa.com/ace/prod/utag.js';
  utagJsScript.type = 'text/javascript';
  utagJsScript.async = true;
  document.body.appendChild(utagJsScript);
}

export const TaggingService = {
  tag,
};
