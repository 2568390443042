import cookieManager from '@/lib/cookieHelper';

export interface AceUserData {
  firstName: string;
  lastName: string;
}

export const getAceUserData = (): AceUserData | undefined => {
  const aceUserCookie = cookieManager('aceuser');
  if (aceUserCookie !== null) {
    const regex = /FirstName=(.+)&LastName=(.+)*/;
    const match = aceUserCookie.match(regex);
    if (match) {
      return {
        firstName: match[1],
        lastName: match[2],
      };
    }
  }
  return undefined;
};
