import { Page, TaggingService } from '@/lib/tagging';
import useAppointmentStore from '@/store/AppointmentStore';
import useSessionStore from '@/store/SessionStore';
import {
  AppHeader,
  Box,
  Button,
  Typography,
} from '@clublabs/shared-component-library';
import { useEffect } from 'react';
import { ulid } from 'ulid';

interface ErrorPageProps {
  onClickTryAgain: () => void;
  message?: string;
  imageUrl?: string;
  buttonLabel?: string;
}

function ErrorPage({
  onClickTryAgain,
  message = 'Sorry an unexpected error has occurred.',
  imageUrl = '/travel/agent/scheduler/errorImage.png',
  buttonLabel = 'Try again',
}: ErrorPageProps) {
  const { sessionId, sessionSource, flowType, setSessionId } =
    useSessionStore();
  const { selectedDestination } = useAppointmentStore();

  useEffect(() => {
    if (!sessionId) {
      const newSessionId = ulid();
      setSessionId(newSessionId);
    } else {
      TaggingService.tag('view', sessionSource, {
        page: Page.errorPage,
        flow: flowType,
        trv_end_location: selectedDestination!.name,
        sessionid: sessionId,
        error: 'system error',
      });
    }
  }, [sessionId]);

  return (
    <Box
      data-testid='errorPageContainer'
      sx={{
        backgroundColor: (themes) => themes.palette.secondary[300],
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      <AppHeader
        sx={{ position: 'absolute', top: 0 }}
        data-testid='appHeader'
      />
      <Typography
        variant='h1'
        sx={{
          fontSize: { mobile: '100px', tablet: '150px', desktop: '290px' },
          opacity: '23%',
          fontWeight: 700,
          color: '#D0D0D0',
          height: '290px',
        }}
        data-testid={'errorTitle'}
      >
        Oops!
      </Typography>
      <Box
        sx={{
          '& img': {
            backgroundSize: 'cover',
            position: 'absolute',
            top: '52%',
            left: '52%',
            transform: 'translate(-50%, -50%)',
            width: {
              desktop: '354px',
              mobile: '300px',
            },
            height: {
              desktop: '135px',
              mobile: '120px',
            },
            zIndex: 2,
          },
        }}
      >
        <img src={imageUrl} aria-label='errorImage' alt='errorImage' />
      </Box>
      <Typography
        data-testid='errorMessage'
        variant='b1Regular'
        sx={{ marginBottom: '20px', marginTop: '20px' }}
      >
        {message}
      </Typography>
      <Button
        data-testid='tryAgainButton'
        label={buttonLabel}
        onClick={onClickTryAgain}
      />
    </Box>
  );
}

export default ErrorPage;
