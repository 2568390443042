import ErrorPage from '@/components/shared/ErrorPage';
import AppointmentPicker from '@/features/appointments/components/AppointmentPicker';
import ContactInfo from '@/features/appointments/components/ContactInfo';
import ScheduleAppointment from '@/features/appointments/routes/ScheduleAppointment';
import AppointmentCancelled from '@/features/confirmation/components/AppointmentCancelled';
import Confirmation from '@/features/confirmation/routes/Confirmation';
import EmailConfirmation from '@/features/confirmation/routes/EmailConfirmation';
import Destinations from '@/features/destinations/routes/Destinations';
import LoadAppointment from '@/features/loadAppointment/routes/LoadAppointment';

import { constants } from '@/constants';
import EmailInquiry from '@/features/appointments/routes/EmailInquiry';
import { FlowType } from '@/types/FlowType';
import { createBrowserRouter } from 'react-router-dom';

const baseName = constants.APP_BASE_URL;

const location = window.location.pathname;
const emailFlowPaths = ['/email', '/email-inquiry', '/email-confirmation'];

const isEmailFlow = emailFlowPaths.some((path) => location.startsWith(path));

const redirectPath = isEmailFlow ? `${constants.APP_BASE_URL}/email` : baseName;

export const routesConfiguration = [
  { path: '/', element: <Destinations flow={FlowType.SCHEDULE} /> },
  { path: '/email', element: <Destinations flow={FlowType.EMAIL} /> },
  {
    path: '/schedule-appointment',
    element: <ScheduleAppointment />,
    children: [
      {
        path: ':id',
        element: <AppointmentPicker />,
      },
      {
        path: ':id/contact-info',
        element: <ContactInfo />,
      },
    ],
  },
  { path: '/confirmation', element: <Confirmation /> },
  { path: '/load-appointment', element: <LoadAppointment /> },
  {
    path: '/email-inquiry',
    element: <EmailInquiry />,
    children: [
      {
        path: ':id/contact-info',
        element: <ContactInfo />,
      },
    ],
  },
  {
    path: '/email-confirmation',
    element: <EmailConfirmation />,
  },
  {
    path: '/reschedule-appointment',
    element: <ScheduleAppointment />,
    children: [
      {
        path: ':id',
        element: <AppointmentPicker />,
      },
      {
        path: ':id/contact-info',
        element: <ContactInfo />,
      },
    ],
  },
  {
    path: '/cancel-appointment/:id',
    element: <AppointmentCancelled />,
  },
  {
    path: '/*',
    element: (
      <ErrorPage
        onClickTryAgain={() => {
          window.location.replace(redirectPath);
        }}
      />
    ),
  },
];

export const router = createBrowserRouter(routesConfiguration, {
  basename: constants.APP_BASE_URL,
});
