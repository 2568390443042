import { apiWrapper } from '@/apiWrapper';
import { getEndpoints } from '@/endpoints';
import type { AgentInfo } from '@/features/appointments/utils/AgentInfo';
import { useQuery } from '@tanstack/react-query';

export const getAgentInfoAndAppointments = async (
  workTypeId: string,
  clubCode: string,
): Promise<AgentInfo[]> => {
  const url = getEndpoints().timeSlots.replace('{workTypeId}', workTypeId);
  const config = { method: 'POST', data: { workTypeId, clubCode } };

  return await apiWrapper<AgentInfo[]>(url, config);
};

export const useAppointmentsQuery = (workTypeId: string, clubCode: string) => {
  return useQuery({
    queryKey: ['timeSlots'],
    queryFn: () => getAgentInfoAndAppointments(workTypeId, clubCode),
  });
};
