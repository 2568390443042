import { constants } from '@/constants';
import { postConfirmation } from '@/features/confirmation/api/postConfirmation';
import { postProcessCustomerAccount } from '@/features/confirmation/api/postProcessCustomerAccount';
import type { CreateAppointmentData } from '@/features/confirmation/utils/CreateAppointmentData';
import type { ProcessCustomerAccountData } from '@/features/confirmation/utils/ProcessCustomerAccountData';
import { Page, TaggingService, getClubCode } from '@/lib/tagging';
import useAppointmentStore from '@/store/AppointmentStore';
import useSessionStore from '@/store/SessionStore';

import ContactInfoForm from '@/components/shared/ContactInfoForm';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useScheduleAppointmentOutletContext } from '../routes/ScheduleAppointment';

function ContactInfo() {
  const {
    setContactInfo,
    setAppointmentId,
    setConfirmationId,
    setCustomerId,
    setAccountId,
    contactInfo,
    selectedAgent,
    selectedDestination,
    selectedStartTime,
    selectedEndTime,
  } = useAppointmentStore();

  const { sessionId, sessionSource, flowType } = useSessionStore();

  const {
    agentInfo,
    handleAppointmentCreationError,
    handleProcessCustomerAccountError,
    handleIsCreatingAppointment,
    handleProcessCustomerAccountDone,
    handleCreateAppointmentDone,
  } = useScheduleAppointmentOutletContext();

  const navigate = useNavigate();

  useEffect(() => {
    TaggingService.tag('view', sessionSource, {
      page: Page.contactInfo,
      sessionid: sessionId,
      flow: flowType,
      trv_end_location: selectedDestination!.name,
    });
  }, [selectedDestination]);

  const processCustomerAccount = useMutation({
    mutationFn: (customerData: ProcessCustomerAccountData) =>
      postProcessCustomerAccount(customerData),
    onSuccess: (data) => {
      handleProcessCustomerAccountDone();
      const createAppointmentData: CreateAppointmentData = {
        workTypeId: selectedDestination?.workTypeId ?? '',
        serviceTerritoryId: selectedAgent?.serviceTerritoryId ?? '',
        schedStartTime: selectedStartTime ?? '',
        schedEndTime: selectedEndTime ?? '',
        appointmentType: contactInfo.communicationPreference as
          | 'Phone'
          | 'Video Conference',
        serviceResourceId: selectedAgent?.resourceId ?? '',
        agentRecordId: selectedAgent?.agentRecordId ?? '',
        firstName: contactInfo.firstName ?? '',
        lastName: contactInfo.lastName ?? '',
        phone: contactInfo.phoneNumber ?? '',
        email: contactInfo.email ?? '',
        zipCode: contactInfo.zipCode ?? '',
        typeOfTripDesired: contactInfo.typeOfTrip ?? [],
        whenDoYouPlanToTravel: contactInfo.expectedTravelTime ?? '',
        destinationsRequested: selectedDestination?.name ?? '',
        clubCode: getClubCode(),
        additionalNotes: contactInfo.additionalInformation ?? '',
        accountId: data?.accountId ?? '',
      };
      setCustomerId(data?.customerId);
      setAccountId(data?.accountId);
      createAppointment.mutate(createAppointmentData);
    },
    onError: (err) => {
      if (err.message !== constants.TANSTACK_ABORTED_ERROR)
        handleProcessCustomerAccountError();
    },
  });

  const createAppointment = useMutation({
    mutationFn: (confirmationData: CreateAppointmentData) =>
      postConfirmation(confirmationData),
    onSuccess: (data) => {
      handleCreateAppointmentDone();
      setAppointmentId(data?.appointmentId);
      setConfirmationId(data?.confirmationId);
      setTimeout(() => {
        navigate('/confirmation', { replace: true });
      }, 250);
    },
    onError: (err) => {
      if (err.message !== constants.TANSTACK_ABORTED_ERROR)
        handleAppointmentCreationError();
    },
  });

  const handleFormSubmit = (data: any) => {
    handleIsCreatingAppointment();
    const processCustomerAccountData: ProcessCustomerAccountData = {
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phoneNumber,
      email: data.emailAddress,
      zipCode: data.zipCode,
      clubCode: getClubCode(),
    };

    processCustomerAccount.mutate(processCustomerAccountData);
  };

  return (
    <ContactInfoForm
      formHeader={`How should ${agentInfo.name.split(' ')[0]} contact you?`}
      fields={[
        'firstName',
        'lastName',
        'zipCode',
        'phoneNumber',
        'email',
        'expectedTravelTime',
        'communicationPreference',
        'typeOfTrip',
        'additionalInformation',
      ]}
      formValues={contactInfo}
      updateField={setContactInfo}
      onSubmit={handleFormSubmit}
    />
  );
}

export default ContactInfo;
