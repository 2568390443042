import { isEnvironmentProd } from '@/endpoints';
import {
  LOG_CANNON_NON_PROD_API_KEY,
  LOG_CANNON_PROD_API_KEY,
} from '@/providers/logCannonConfig';

import { TeamValue, WorkloadValue } from '@clublabs/aws-tags';
import { LogCannon } from '@clublabs/log-cannon';

export const initLogCannon = () => {
  const environmentIsProd = isEnvironmentProd(window.location.hostname);

  const apiKey = environmentIsProd
    ? LOG_CANNON_PROD_API_KEY
    : LOG_CANNON_NON_PROD_API_KEY;

  const logCannonOptionsTRVL: any = {
    team: TeamValue.Travel,
    workload: WorkloadValue.TravelScheduler,
    component: 'apiWrapper',
    runtime: 'nodejs',
    runtimeVersion: '20.12.2',
  };

  //nonprod LogCannon requires overridden endpoint property
  if (!environmentIsProd) {
    logCannonOptionsTRVL.endpoint = 'https://apiuat1.ace.aaa.com/log-cannon/';
  }

  LogCannon.init(apiKey, logCannonOptionsTRVL);
};
