import { Button as SCLButton } from '@clublabs/shared-component-library';

interface ButtonProps {
  label: string;
  onClickHandler: () => void;
  disabled?: boolean;
  variant?: 'outlined' | 'text' | 'contained'; // change this to extend ButtonProps with the Button props from SCL
  type?: 'button' | 'submit' | 'reset' | undefined;
}

function Button({
  label,
  onClickHandler,
  variant,
  disabled,
  type = 'button',
}: ButtonProps) {
  return (
    <SCLButton
      onClick={onClickHandler}
      label={label}
      variant={variant}
      disabled={disabled}
      sx={{ padding: 0 }}
      fullWidth={true}
      data-testid='customButton'
      type={type}
    />
  );
}

export default Button;
