import { AppHeader, Box, Stack } from '@clublabs/shared-component-library';
import type React from 'react';
import type { ReactNode } from 'react';

type SplitPageLayoutProps = {
  leftSide: ReactNode;
  rightSide: ReactNode;
};

const SplitPageLayout: React.FC<SplitPageLayoutProps> = ({
  leftSide,
  rightSide,
}) => {
  return (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: {
          mobile: 'column',
          tablet: 'column',
          desktop: 'row',
        },
        height: '100%',
        width: '100%',
      }}
    >
      <AppHeader
        sx={{
          display: {
            desktop: 'none',
            large: 'none',
            tablet: 'block',
            mobile: 'block',
          },
          backgroundColor: (themes) => themes.palette.grey[100],
        }}
      />
      <Stack
        direction={'column'}
        flexWrap={'wrap'}
        justifyContent={'center'}
        sx={{
          alignItems: 'center',
          flexShrink: '3',
          width: {
            desktop: '35%',
            large: '35%',
            tablet: '100%',
            mobile: '100%',
          },
          paddingLeft: {
            desktop: '48px',
            large: '48px',
            tablet: '0px',
            mobile: '0px',
          },
          paddingRight: {
            desktop: '48px',
            large: '48px',
            tablet: '0px',
            mobile: '0px',
          },
          backgroundColor: (themes) => themes.palette.grey[100],
          height: '100%',
          position: 'relative',
        }}
      >
        {leftSide}
      </Stack>
      <Box
        sx={{
          width: { mobile: '100%', desktop: '70%' },
          backgroundColor: (themes) => themes.palette.secondary.main,
          height: {
            desktop: '100%',
            large: '100%',
            mobile: 'auto',
            tablet: 'auto',
          },
          display: 'flex',
          flexDirection: 'column',
          flex: '2 1 auto',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <AppHeader
          className={'childContainerHeaderLogo'}
          sx={{
            display: {
              desktop: 'block',
              large: 'block',
              tablet: 'none',
              mobile: 'none',
            },
          }}
        />
        {rightSide}
      </Box>
    </Stack>
  );
};

export default SplitPageLayout;
