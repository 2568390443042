import { Button } from '@clublabs/shared-component-library';

interface TimeSlotProps {
  label: string;
  onClickHandler: () => void;
  isSelected: boolean;
  testId?: string;
}

const TimeSlot = ({
  label,
  onClickHandler,
  isSelected,
  testId,
}: TimeSlotProps) => {
  return (
    <Button
      aria-selected={isSelected}
      label={label}
      variant={'outlined'}
      onClick={onClickHandler}
      data-testid={`timeButton-${testId}`}
      sx={{
        width: '99%',
        minHeight: '60px',
        fontSize: '20px',
        backgroundColor: (themes) =>
          isSelected
            ? themes.palette.primary[300]
            : themes.palette.common.white,
        color: (themes) =>
          isSelected
            ? themes.palette.common.white
            : themes.palette.primary[300],
        '&:hover': {
          backgroundColor: (themes) =>
            isSelected ? themes.palette.primary[300] : 'default',
          color: (themes) =>
            isSelected ? themes.palette.common.white : 'default',
        },
        '& .MuiButton-root': {
          lineHeight: 'auto',
        },
      }}
    />
  );
};

export default TimeSlot;
