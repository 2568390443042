import useAppointmentStore from '@/store/AppointmentStore';
import { useEffect } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';

const useLandingPageRedirection = () => {
  const { selectedDestination } = useAppointmentStore();
  const navigate = useNavigate();
  const isEmailMatch = useMatch('/email/*');
  const isEmailInquiryMatch = useMatch('/email-inquiry/*');
  const isEmailConfirmationMatch = useMatch('/email-confirmation/*');

  const isEmailFlow =
    isEmailMatch || isEmailInquiryMatch || isEmailConfirmationMatch;
  const navigateTo = isEmailFlow ? '/email' : '/';

  useEffect(() => {
    if (
      !selectedDestination?.workTypeId ||
      !selectedDestination.workTypeId.length
    ) {
      navigate(navigateTo);
    }
  }, [selectedDestination, navigate]);

  return navigateTo;
};

export default useLandingPageRedirection;
