import { apiWrapper } from '@/apiWrapper';
import { getEndpoints } from '@/endpoints';
import type { CustomerAccount } from '@/features/confirmation/utils/CustomerAccount';
import type { ProcessCustomerAccountData } from '@/features/confirmation/utils/ProcessCustomerAccountData';

export const postProcessCustomerAccount = async (
  inputData: ProcessCustomerAccountData,
): Promise<CustomerAccount> => {
  const url = getEndpoints().customerAccount;
  const config = { method: 'POST', data: inputData };

  return await apiWrapper<CustomerAccount>(url, config);
};
