import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from '@clublabs/shared-component-library';

interface CancelAppointmentModalBodyProps {
  onClickCancel: () => void;
  onClickClose: () => void;
  isCancellingAppointment: boolean;
}

function CancelAppointmentModalBody({
  onClickCancel,
  onClickClose,
  isCancellingAppointment,
}: CancelAppointmentModalBodyProps) {
  return (
    <Box
      sx={{
        padding: '0 25px 25px 25px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography data-testid='warningMessage'>
        Cancelling your appointment will result in losing your time slot.
      </Typography>
      <Stack
        direction={'row'}
        gap={'10px'}
        sx={{
          marginTop: '20px',
          alignSelf: 'flex-end',
        }}
      >
        <Button
          variant='outlined'
          label='Cancel'
          onClick={onClickCancel}
          sx={{
            width: '146px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            '& .MuiButton-endIcon': {
              height: '20px',
              width: '20px',
            },
          }}
          endIcon={
            isCancellingAppointment ? (
              <CircularProgress size={20} data-testid='circularProgress' />
            ) : null
          }
        />
        <Button label='Close' onClick={onClickClose} sx={{ width: '146px' }} />
      </Stack>
    </Box>
  );
}

export default CancelAppointmentModalBody;
