import {
  Box,
  LinearProgress,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@clublabs/shared-component-library';

type LoadingContainerProps = {
  message?: string;
  showRadios?: boolean;
  radiosInfo?: {
    radioMessage: string;
    isChecked: boolean;
  }[];
};

function LoadingContainer({
  message,
  showRadios = true,
  radiosInfo,
}: LoadingContainerProps) {
  return (
    <Stack
      data-testid='loadingContainer'
      gap={7}
      justifyContent={'center'}
      alignItems={'center'}
      direction={'column'}
      sx={{
        minHeight: '100vh',
        width: '100%',
        backgroundColor: (themes) => themes.palette.secondary[300],
      }}
    >
      <Typography variant='h3'>{message}</Typography>
      <Box sx={{ width: '80%' }}>
        <LinearProgress
          data-testid='linearProgressBar'
          variant='indeterminate'
          aria-label='progress'
          sx={{
            height: '8px',
            backgroundColor: (themes) => themes.palette.primary[200],
          }}
        />
      </Box>
      {showRadios && (
        <RadioGroup
          variant='text'
          sx={{
            '& .MuiRadio-FormControlLabel:hover': {
              backgroundColor: 'inherit !important',
              cursor: 'default',
            },
            '& .MuiRadio-colorPrimary:hover': {
              cursor: 'default',
            },
            textAlign: 'left',
          }}
          data-testid='radioGroup'
        >
          {radiosInfo?.map((radio) => {
            return (
              <Radio
                key={radio.radioMessage}
                label={radio.radioMessage}
                checked={radio.isChecked}
              />
            );
          })}
        </RadioGroup>
      )}
    </Stack>
  );
}

export default LoadingContainer;
