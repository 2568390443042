import { getEndpoints } from '@/endpoints';
import { useQuery } from '@tanstack/react-query';

import { apiWrapper } from '@/apiWrapper';
import type { Destination } from '@/features/destinations/utils/Destination';

export const getDestinations = async (): Promise<Destination[]> => {
  const url = getEndpoints().destinations;
  const config = { method: 'GET' };

  return await apiWrapper<Destination[]>(url, config);
};

export const useDestinations = () => {
  return useQuery({
    queryKey: ['destinations'],
    queryFn: () => getDestinations(),
    staleTime: Number.POSITIVE_INFINITY,
  });
};
