import type { Destination } from '@/features/destinations/utils/Destination';
import {
  Card,
  Icon,
  Stack,
  Typography,
} from '@clublabs/shared-component-library';
import dayjs from 'dayjs';

interface AgentInfoConfirmationCardProps {
  agentName: string;
  selectedDate?: Date;
  selectedStartTime?: string;
  selectedEndTime?: string;
  selectedDestination?: Destination;
}

function AgentInfoContactInfoPage({
  agentName,
  selectedDate,
  selectedStartTime,
  selectedEndTime,
  selectedDestination,
}: AgentInfoConfirmationCardProps) {
  return (
    <Stack
      direction={'row'}
      flexWrap={'wrap'}
      data-testid='callInfoCard'
      sx={{
        width: '100%',
        textAlign: 'left',
      }}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Card
        sx={{
          width: {
            mobile: '80%',
            desktop: '100%',
          },
          marginTop: '20px',
          marginBottom: '20px',
          padding: '1.5rem 1rem 1rem 1rem',
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          gap: '20px',
        }}
      >
        <Stack direction={'row'} alignItems={'center'} gap={'10px'}>
          <Icon
            iconName='calendar-check-duotone'
            color='primary'
            sx={{ width: '35px', height: '35px' }}
          />
          <Typography
            sx={{
              fontWeight: '700',
              fontSize: { mobile: '16px', desktop: '24px' },
            }}
          >
            30 minute call with {agentName.split(' ')[0]}
          </Typography>
        </Stack>
        <Stack
          direction={'row'}
          alignItems={'baseline'}
          gap={'5px'}
          data-testid='dateAndTimeInfo'
        >
          <Typography
            variant='h6Medium'
            sx={{ margin: 0, padding: 0, textAlign: 'left' }}
          >
            When:
          </Typography>
          <Typography variant='b1Regular'>
            {dayjs(selectedDate).format('dddd, MMMM D')}{' '}
            {selectedStartTime &&
              selectedEndTime &&
              `${dayjs(new Date(selectedStartTime)).format('hh:mma')} - ${dayjs(
                new Date(selectedEndTime),
              ).format('hh:mma')}`}
          </Typography>
        </Stack>
        <Stack
          direction={'row'}
          alignItems={'baseline'}
          gap={'5px'}
          data-testid='destinationInfo'
        >
          <Typography variant='h6Medium' sx={{ margin: 0, padding: 0 }}>
            Destination:
          </Typography>
          <Typography variant='b1Regular' sx={{ margin: 0, padding: 0 }}>
            {selectedDestination?.name}
          </Typography>
        </Stack>
      </Card>
    </Stack>
  );
}

export default AgentInfoContactInfoPage;
