import { create } from 'zustand';

export type SessionSource = '' | 'new' | 'email';
export type FlowType = '' | 'modify' | 'cancel' | 'emailInquiry';

interface SessionState {
  sessionId: string;
  sessionSource: SessionSource;
  flowType: FlowType;
  setSessionId: (sessionId: string) => void;
  setSessionSource: (sessionSource: SessionSource) => void;
  setFlowType: (flowType: FlowType) => void;
}

const useSessionStore = create<SessionState>((set) => ({
  sessionId: '',
  sessionSource: '',
  flowType: '',
  setSessionId: (sessionId) => set({ sessionId: sessionId }),
  setSessionSource: (sessionSource) => set({ sessionSource: sessionSource }),
  setFlowType: (flowType) => set({ flowType: flowType }),
}));

export default useSessionStore;
